// DayGrid view
+prefix-classes(daygrid)
    .layout
        height: 100%;

.{css-prefix}daygrid-layout
    .{css-prefix}right
        overflow-y: hidden

// Guide element
+prefix-classes(daygrid + guide-)
    .creation-block
        position: absolute
        top: 0
        bottom: 0
        z-index: 1
