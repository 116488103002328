/* icon styles */
.{css-prefix}icon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}

.{css-prefix}icon.{css-prefix}ic-title
    background: url('image/ic-subject.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-location
    background: url('image/ic-location.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-date
    background: url('image/ic-date.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-state
    background: url('image/ic-state.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-private
    background: url('image/ic-lock.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-close
    background: url('image/ic-close.png') no-repeat


.{css-prefix}ic-location-b
    background: url('./image/ic-location-b.png')

.{css-prefix}ic-state-b
    background: url('./image/ic-state-b.png')

.{css-prefix}icon.{css-prefix}ic-user-b
    background-image: url('./image/ic-user-b.png')

.{css-prefix}icon.{css-prefix}ic-edit
    background-image: url('./image/ic-edit.png')

.{css-prefix}icon.{css-prefix}ic-delete
    background-image: url('./image/ic-delete.png')

.{css-prefix}icon.{css-prefix}ic-arrow-solid-top
    background: url('image/ic-arrow-solid-top.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-milestone
    background: url('image/ic-milestone.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-arrow-left
    background: url('image/ic-arrow-left.png') no-repeat

.{css-prefix}icon.{css-prefix}ic-arrow-right
    background: url('image/ic-arrow-right.png') no-repeat

.{css-prefix}ic-repeat-b
    background: url('./image/ic-repeat-b.png')
