+prefix-classes(timegrid)
    .container
        height: 100%
        position: relative
        overflow: hidden
        overflow-y: scroll

    .container-split
        height: 100%
        position: relative
        overflow: hidden

    .left
        position: absolute

    .hour
        position: relative
        color: #555
        box-sizing: border-box
        &:first-child span
            display: none
        &:last-child
            border-bottom: none

    .hour span
        position: absolute
        top: -11px
        left: 0
        right: 5px
        text-align: right
        line-height: 25px

    .right
        position: relative

    .gridline
        border-bottom: 1px solid #eee
        box-sizing: border-box
        &:last-child
            border-bottom: none

    .schedules
        position: absolute
        wh(100%, 100%)
        pos(0, 0)
        cursor: pointer

    // Hourmarker
    .hourmarker
        position: absolute
        width: 100%
        display: table

    .hourmarker-line-left
        position: absolute
        min-height: 1px
        left: 0

    .hourmarker-line-today
        position: absolute
        min-height: 1px

    .hourmarker-line-right
        position: absolute
        min-height: 1px
        right: 0

    .hourmarker-time
        padding-right: 5px
        line-height: 12px
        text-align: right
        display: table-cell
        vertical-align: bottom

    // Todaymarker
    .todaymarker
        position: absolute
        text-indent: -9999px
        wh(9px, 9px)
        background-color: #135de6
        margin: -4px 0 0 -5px
        border-radius: 50%

    .sticky-container
        position: absolute
        top: 0
    
    .timezone-label-container
        position: absolute
    
    .timezone-label-cell
        display: table
    
    .timezone-label
        display: table-cell
        vertical-align: middle
        padding-right: 5px
        text-align: right
    
    .timezone-close-btn
        cursor: pointer
        position: absolute
        text-align: center
        background-color: #ffffff

.{css-prefix}timegrid-timezone-close-btn
    .{css-prefix}icon
        width: 5px
        height: 10px

+prefix-classes(time)
    .date
        position: absolute
        height: 100%
        margin-left: -1px
        box-sizing: content-box
        &:last-child
            border-right: none
            margin: 0
            // last creation guide element
            .schedule,
            .guide-creation
                left: 0px

    .date-schedule-block-wrap
        position: relative
        height: 100%

    .date-schedule-block
        position: absolute
        right: 0px

    .date-schedule-block-pending
        opacity: 0.7

    .date-schedule-block-dragging-dim
        opacity: 0.3

    .date-schedule-block-focused
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2)

    .date-schedule-block-cover
        position: absolute
        top: 0
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, 0.2)
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

    .schedule
        position: relative
        left: 1px
        height: 100%
        overflow: hidden
        font-size: 12px
        font-weight: bold

    .schedule-content
        overflow: hidden
        border-left-width: 3px
        border-left-style: solid
        padding: 1px 0 0 3px

    .schedule-content-travel-time
        font-weight: normal;
        font-size: 11px;

    .resize-handle
        position: absolute
        right: 0px
        bottom: 0px
        left: 0px
        height: 5px
        text-align: center
        color: #fff
        cursor: row-resize
        background-position: center top

    // Guide element
    .guide-creation
        position: absolute
        right: 10px
        left: 1px
        padding: 3px

    .guide-move,
    .guide-resize
        .schedule,
        .resize-handle
            opacity: 0.8
            z-index: 97

    .guide-creation-label
        cursor: default

    .guide-bottom
        position: absolute
        bottom: 3px
