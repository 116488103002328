+prefix-classes(css-prefix)
    .vlayout-container
        position: relative

    .splitter
        clear: left
        cursor: row-resize

        &:hover
            border-color: #999

    .splitter-focused
        background-color: #ddd
        border: none

    .splitter-guide
        position: absolute
        wh(100%, 3px)
        border: none
        background-color: #e8e8e8
