+prefix-classes(css-prefix)
    .layout
        height: 100%
        position: relative
        box-sizing: border-box

        *
            box-sizing: border-box

    // Related with user interaction
    .dragging
        cursor: move

    .resizing
        cursor: row-resize

    .resizing-x
        cursor: col-resize

    .hidden
        display: none !important

    .invisible span
        visibility: hidden

    .clear
        clearfix()

    .scroll-y
        overflow-y: scroll

    .dot
        display: inline-block
        position: relative
        top: -1px
        content: ''
        width: 7px
        height: 7px
        border-radius: 50%

.{css-prefix}holiday
    color: red

.{css-prefix}today
    background: rgba(218, 229, 249, .3)

// Drag handle
.handle-x
    background-position: center center
    background-repeat: no-repeat
    background-image: url(./image/handle-x.png)

.handle-y
    background-position: center center
    background-repeat: no-repeat
    background-image: url(./image/handle-y.png)

// For Retina display
@media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-devicepixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx)
    .handle-x
        background-image: url(./image/handle-x@2x.png)
        background-size: 8px 4px

    .handle-y
        background-image: url(./image/handle-y@2x.png)
        background-size: 4px 8px
