.{css-prefix}month
    height: 100%
    min-height: 600px

+prefix-classes(month)
    .dayname
        width: 100%
        position: absolute
        font-size: 13px

    .dayname-item
        height: 100%
        font-weight: bold

    .week-item
        position: relative
        &>div
            height: 100%

    .more
        height: inherit
        min-width: 280px
        min-height: 150px

    .more-title
        position: relative
    
    .more-title-day
        font-size: 23px
        color #333
    
    .more-title-day-label
        font-size: 12px
        color: #333

    .more-close
        position: absolute
        right: 0
        outline: 0
        background none
        border: 0
        font-size: 14px
        line-height: 28px
        padding: 0 7px
        cursor: pointer

    .more-list
        overflow-y: auto

    .more-schedule
        cursor: pointer
        display: block
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        font-size: 12px

    .guide-block
        position: absolute

    .weekday-schedule
        margin-top: 2px

    .creation-guide
        top: 0
        bottom: -1px
        left: -1px
        right: 0
        position: absolute
        z-index: 20

    .guide-focused
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2)

    .guide
        position: relative
        padding-left: 3px
        line-height: 18px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

    .guide-cover
        width: 100%
        position: absolute
        top: -50%
        left: -50%
        background-color: rgba(0, 0, 0, 0.2)
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1)

    .exceed-left .guide
        margin-left: 0px

    .exceed-right .guide
        margin-right: 0px

    .exceed-right .guide-handle
        display: none

    .guide-handle
        position: absolute
        top: 0
        right: 3px
        width: 6px
        background-position: 3px center
        cursor: col-resize
