.{css-prefix}dayname
    position: absolute
    margin-left: -1px
    height: 100%
    overflow: hidden

    &.{css-prefix}today
        font-weight: bold

+prefix-classes(dayname)
    .container
        overflow-y: scroll

    .leftmargin
        position: relative
        height: 100%
    
    .date
        font-size: 26px
    
    .name
        font-weight: bold
        font-size: 12px
