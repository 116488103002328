.{css-prefix}month-week-item
    .{css-prefix}weekday-grid
        overflow-y: hidden

    .{css-prefix}weekday-schedules
        overflow-y: visible
        height: 0

    .{css-prefix}weekday-schedule
        margin: 0 10px

    .{css-prefix}today
        background: none

        .{css-prefix}weekday-grid-date-decorator
            display: inline-block
            wh(27px, 27px)
            line-height: 27px
            text-align: center
            background: #135de6
            border-radius: 50%
            color: #fff
            font-weight: bold
            margin-left: 2px

+prefix-classes(weekday)
    .grid,
    .grid-line
        height: 100%
        min-height: inherit

    .grid
        position: absolute
        width: 100%
        overflow-y: scroll

    .border
        border-top: 1px solid #ddd

    .grid-line
        position: absolute
        padding: 3px

        .grid-footer
            position: absolute
            bottom: 4px

        .grid-date
            display: inline-block
            wh(27px, 27px)
            line-height: 27px
            text-align: center

        .grid-more-schedules
            float: right
            display: inline-block
            height: 27px
            line-height: 27px
            padding: 0 5px
            text-align: center
            font-size: 11px
            font-weight: bold
            color: #aaaaaa

    .creation
        pos(0, 0)
        wh(100%, 100%)
        position: absolute
        overflow-y: scroll

    .schedules
        pos(0, 0)
        wh(100%, 100%)
        position: absolute
        font-size: 12px
        overflow-y: scroll

    .schedule-block
        position: absolute

    .schedule-block-dragging-dim
        opacity: 0.3

    .schedule
        position: relative
        margin: 0 10px 0 1px
        cursor: pointer
        border-left-style: solid
        border-left-width: 3px

        &.schedule-time
            border-left-width: 0

            .schedule-title
                padding-left: 9px

    .schedule-bullet
        position: absolute
        padding: 0
        wh(6px, 6px)
        top: 6px
        left: 0
        border-radius: 50%

    .schedule-bullet-focused
        left: 10px
        background: #ffffff

    .schedule-title
        display: block
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        padding-left: 3px;
        font-weight: bold;

    .schedule-title-focused
        padding-left: 16px

    .schedule-cover
        position: absolute
        top: 0
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, 0.2)
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

    .exceed-left .schedule
        margin-left: 0
        border-left-width: 0

    .exceed-right .schedule
        margin-right: 0

    .exceed-right .resize-handle
        display: none

    .exceed-in-month
        cursor: pointer
        &:hover
            background-color: #f0f1f5

    .exceed-in-week, .collapse-btn
        position: absolute
        bottom: 5px
        margin-right: 5px
        font-size: 12px
        line-height: 14px
        cursor: pointer
        padding: 1px 5px
        background-color: #ffffff
        border: 1px solid #dddddd
        color: #000000

    .resize-handle
        position: absolute
        top: 0
        right: 0
        width: 6px
        background-position: 3px center
        cursor: col-resize
        line-height: 18px

    .filled
        background-color: #e8e8e8 !important

+prefix-classes(css-prefix)
    .left
        height: 100%
        float: left
        box-sizing: border-box
        display: table

    .left-content
        display: table-cell
        vertical-align: middle
        text-align: right
        font-size: 11px


    .right
        height: 100%
        overflow-y: scroll
        position: relative
